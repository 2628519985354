/*=====================
    General CSS
=======================*/

.react-tabs__tab-list {
    border-bottom: 1px solid #aaa;
    margin: 30px 0 10px;
    text-align: center;
    padding: 0;

    .react-tabs__tab {
        display: inline-block;
        border: 1px solid transparent;
        border-bottom: none;
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 6px 20px;
        margin: 0 30px;
        cursor: pointer;
        h3 {
            color: #ffffff;
            margin-bottom: 0;
        }
    }

    .react-tabs__tab--selected {
        background: #fff;
        border-color: #aaa;
        color: black;
        border-radius: 5px 5px 0 0;
        h3 {
            color: black;
            margin-bottom: 0;
        }
    }

    .react-tabs__tab--disabled {
        color: #aaa;
        cursor: default;
    }
}

.table-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    width: 600px;

    .table-header {
        color: #7A9757;
        font-weight: bold;
    }

    .table-row {
        flex: 1 1 auto;
        width: 100%;
        display: flex;
        flex-direction: row;

        .table-col {
            flex: 0 0 33%;
            border: 1px solid white;
        }
    }
}
  
  