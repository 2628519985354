/*=======================
    Banner Area 
========================*/


body .demo-content {
    padding-top: 230px;
    height: auto;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    color: #ffffff;

    @media #{$md-layout} {
        padding-top: 150px;
    }

    @media #{$sm-layout} {
        padding-top: 120px;
    }

    .container.h-100 {
        @media #{$lg-layout} {
            height: 85%!important;
        }
    }
    .row {
        height: 100%;
        text-align: center;
        
        &.result-container {
            padding: 35px 0 10px 0;

            .result-header {
                h3 {
                    color: #ffffff;
                }
            }
        }
    }
    .banner-text {
        height: 80%;
        display: table;
        @media #{$lg-layout} {
            height: 100%;
        }
        @media #{$md-layout} {
            height: 100%;
            padding: 0 2em 0 2em;
            text-align: center;
        }
        @media #{$sm-layout} {
            height: 100%;
            padding: 0;
            text-align: center;
        }
        .banner-table-cell {
            display: table-cell;
            vertical-align: middle;
            z-index: 9;
            position: relative;
            h1 {
                margin-right: 40px;
                font-size: 28px;
                font-weight: 500;
                line-height: 30px;
                color: #ffffff;

                .app-name {
                    font-size: 60px;
                    line-height: 60px;
                    font-weight: 700;
                }

                @media #{$lg-layout} {
                    margin-right: 30px;
                    font-size: 20px;
                    line-height: 22px;
                    .app-name {
                        font-size: 40px;
                        line-height: 40px;
                        font-weight: 700;
                    }
                }
                @media #{$md-layout} {
                    margin-right: 0px;
                    font-size: 28px;
                    line-height: 32px;
                    .app-name {
                        font-size: 40px;
                        line-height: 40px;
                        font-weight: 700;
                    }
                }
                @media #{$sm-layout} {
                    margin-right: 0px;
                    font-size: 20px;
                    line-height: 22px;
                    .app-name {
                        font-size: 40px;
                        line-height: 40px;
                        font-weight: 700;
                    }
                }
                @media #{$small-mobile} {
                    margin-right: 0px;
                    font-size: 18px;
                    line-height: 20px;
                    .app-name {
                        font-size: 36px;
                        line-height: 36px;
                        font-weight: 600;
                    }
                }
            }
            p {
                color: #ffffff;
                margin-bottom: 33px;
            }
            .banner-buttons {
                .button-default {
                    margin-right: 26px;
                    @media #{$sm-layout} {
                        margin-bottom: 20px;
                    } 
                }
            }
        }
    }

    .contact-inner {
        height: 80%;
        display: table;
        width: 100%;
        .demo-form {
            margin-top: 46px;
        }
        @media #{$lg-layout} {
            height: 100%;
        }
        @media #{$md-layout} {
            margin: 60px 0 0 0;
        }
        @media #{$sm-layout} {
            margin: 60px 0 0 0;
        }
        .contact-inner-table {
            display: table-cell;
            vertical-align: middle;
            z-index: 9;
            position: relative;
            h3 {
                line-height: 40px;

                @media #{$md-layout} {
                    text-align: center;
                }
                @media #{$sm-layout} {
                    text-align: center;
                }
            }
            @media #{$lg-layout} {
                height: 100%;
            }
        }
    }
    .button {
        background-color: #7a9757;
        color: #ffffff;
        border-radius: 7px;
        font-family: $heading-font;
        height: 44px;
        margin-top: 21px;
        text-transform: uppercase;
        -webkit-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        width: 100%;
        border: 0;
        &:hover {
            background-color: #fff;
            color: #2d3e50;
        }

        &.calc-button {
            background-color: #2185d0;
            color: #fff;
            &:hover {
                background-color: #fff;
                color: #2d3e50;
            }
        }
    
        &.clean-button {
            background-color: #848587;
            color: #fff;
            &:hover {
                background-color: #fff;
                color: #2d3e50;
            }
        }
    }
    
    .recharts-wrapper {
        margin-left: auto;
        margin-right: auto;
    }
    
    .consolidated-well-results {
        .title-wrapper {
            border-bottom: 1px solid #aaa;
            margin: 30px 0 10px;
            text-align: center;
            padding: 0;
            display: inline-block;
            width: 100%;
        
            .title {                
                border: 1px solid transparent;
                border-bottom: none;
                bottom: -1px;
                position: relative;
                list-style: none;
                padding: 6px 20px;
                margin: 0 auto;
                background: #fff;
                border-color: #aaa;
                color: black;
                border-radius: 5px 5px 0 0;
                width: fit-content;

                h3 {
                    color: black;
                    margin-bottom: 0;
                }
            }
        }
    }
}