/*=======================
    Banner Area 
========================*/


.slider-area {
    height: 1196px;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;

    @media #{$lg-layout} {
        height: 965px;
    }

    @media #{$md-layout} {
        height: auto;
        padding: 150px 0;
        padding-bottom: 58px;
    }

    @media #{$sm-layout} {
        height: auto;
        padding: 150px 0;
        padding-bottom: 58px;
    }

    &.bg-shape{
        &::before{
            background-image: url(/assets/images/app/shape.png);
            background-repeat: no-repeat;
            background-position: left top;
            background-size: cover;
            content: "";
            height: 365px;
            left: 0;
            position: absolute;
            width: 100%;
            z-index: 2;
            bottom: 0px;
            right: 0;
            top: auto;
            -webkit-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
            @media #{$lg-layout} {
                height: 274px;
            }
            @media #{$md-layout} {
                background-image: inherit;
                height: auto;
            }
            @media #{$sm-layout} {
                background-image: inherit;
                height: auto;
            }
        }
    }
    
    &.horizontal{
        height: 1075px;
        @media #{$md-layout} {
            height: auto;
        }
        @media #{$sm-layout} {
            height: auto;
        }
        &.bg-shape {
            &::before {
                height: 212px;
                background-color: #ffffff;
                background-image: inherit;
                
                @media #{$lg-layout} {
                    height: auto;
                }
                @media #{$md-layout} {
                    height: auto;
                }
                @media #{$sm-layout} {
                    height: auto;
                }
            }
        }
    }
    .container.h-100 {
        @media #{$lg-layout} {
            height: 85%!important;
        }
    }
    .row{
        height: 100%;
    }
    .banner-text {
        height: 80%;
        display: table;
        @media #{$lg-layout} {
            height: 100%;
        }
        @media #{$md-layout} {
            height: 100%;
            padding: 0 2em 0 2em;
            text-align: center;
        }
        @media #{$sm-layout} {
            height: 100%;
            padding: 0;
            text-align: center;
        }
        .banner-table-cell {
            display: table-cell;
            vertical-align: middle;
            z-index: 9;
            position: relative;
            h1 {
                margin-right: 40px;
                font-size: 28px;
                font-weight: 500;
                line-height: 30px;
                color: #ffffff;

                .app-name {
                    font-size: 60px;
                    line-height: 60px;
                    font-weight: 700;
                }

                @media #{$lg-layout} {
                    margin-right: 30px;
                    font-size: 20px;
                    line-height: 22px;
                    .app-name {
                        font-size: 40px;
                        line-height: 40px;
                        font-weight: 700;
                    }
                }
                @media #{$md-layout} {
                    margin-right: 0px;
                    font-size: 28px;
                    line-height: 32px;
                    .app-name {
                        font-size: 40px;
                        line-height: 40px;
                        font-weight: 700;
                    }
                }
                @media #{$sm-layout} {
                    margin-right: 0px;
                    font-size: 20px;
                    line-height: 22px;
                    .app-name {
                        font-size: 40px;
                        line-height: 40px;
                        font-weight: 700;
                    }
                }
                @media #{$small-mobile} {
                    margin-right: 0px;
                    font-size: 18px;
                    line-height: 20px;
                    .app-name {
                        font-size: 36px;
                        line-height: 36px;
                        font-weight: 600;
                    }
                }
            }
            p {
                color: #ffffff;
                margin-bottom: 33px;
            }
            .banner-buttons {
                .button-default {
                    margin-right: 26px;
                    @media #{$sm-layout} {
                        margin-bottom: 20px;
                    } 
                }
            }
        }
    }

    .contact-inner {
        height: 80%;
        display: table;
        width: 90%;
        @media #{$lg-layout} {
            height: 100%;
        }
        @media #{$md-layout} {
            width: 100%;
            margin: 60px 0 0 0;
        }
        @media #{$sm-layout} {
            width: 100%;
            margin: 60px 0 0 0;
        }
        .contact-inner-table {
            display: table-cell;
            vertical-align: middle;
            z-index: 9;
            position: relative;
            h3 {
                line-height: 40px;

                @media #{$md-layout} {
                    text-align: center;
                }
                @media #{$sm-layout} {
                    text-align: center;
                }
            }
            @media #{$lg-layout} {
                height: 100%;
            }
        }
    }
}