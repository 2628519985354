/*====================
    Benefits Area 
======================*/

.app-benefits{
    @media #{$md-layout} {
        padding-bottom: 80px;
        padding-top: 80px;
    }
    @media #{$sm-layout} {
        padding-bottom: 80px;
        padding-top: 80px;
    }

    .benefits-item {
        display: flex;
        flex-direction: row;

        .benefits-image {
            max-width: 100px;
        }
        .benefits-content{
            .title {
                font-size: 28px;
                font-weight: 800;
                margin-bottom: 19px;
            }
            p {    
                margin-bottom: 25px;
            }
            .benefits-buttons {
                margin-top: 48px;
                button {
                    margin-right: 26px;
                    @media #{$sm-layout} {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

}






