/*======================
    Opinions 
========================*/

.opinions-area {
    min-height: 1420px;
    padding-top: 360px;
    position: relative;

    @media #{$lg-layout} {
        min-height: 1420px;
        padding-top: 320px;
    }

    @media #{$md-layout} {
        padding-bottom: 120px;
        padding-top: 120px;
        position: relative;
    }

    @media #{$sm-layout} {
        padding-bottom: 120px;
        padding-top: 120px;
        position: relative;
    }

    &::after{
        background: #f0f0f0 none repeat scroll 0 0;
        content: "";
        height: 1075px;
        left: 0;
        position: absolute;
        top: 10%;
        -webkit-transform: skewY(165deg);
        -ms-transform: skewY(165deg);
        transform: skewY(165deg);
        width: 100%;
        z-index: -1;

        @media #{$lg-layout} {

        }

        @media #{$md-layout} {
            transform: skewY(0) translateY(-50%);
            top: 50%;
            height: 100%;
        }
        @media #{$sm-layout} {
            transform: skewY(0) translateY(-50%);
            top: 50%;
            height: 100%;
        }
    }
}
.opinion-container {
    @media #{$sm-layout} {
        margin: 20px 0 0 0;
    }
    @media #{$md-layout} {
        margin: 20px 0 0 0;
    }
}
.opinion {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 440px;

    @media #{$sm-layout} {
        height: auto;
    }
    @media #{$md-layout} {
        height: auto;
    }
    @media #{$lg-layout} {
        height: 540px;
    }

    .description {
        padding: 0 20px 0 20px;
        margin: auto 0 auto 0;

        .desc-text {
            margin: 0;
            padding: 0 30px 0 30px;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
        }

        .quote-start {
            width: 50px;
            height: 50px;
            background-image: url('../../images/opinions/quotation-1.svg');
            content: "";
        }

        &::after {
            float: right;
            width: 50px;
            height: 50px;
            margin-top: -10px;
            background-image: url('../../images/opinions/quotation-2.svg');
            content: "";
        }
    }
    .person {
        text-align: center;
        margin-top: -15px;
        
        .person-picture {
            margin: 0 auto 0 auto;
            width: fit-content;
            img{
                border-radius: 50%;
                border-style: solid;
                border-color: black;
                border-width: medium;
                max-width: 120px;
            }
        }
        h4 {
            margin: 0;
        }
    }
}

.position {
    padding: 0 30px 0 30px;
    text-align: center;
    font-weight: bold;
}








