/*====================
    Footer Area 
======================*/

.footer-area {
    background: rgb(45, 62, 80);
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    padding-bottom: 118px;
    position: relative;
    z-index: 1;
    &.footer-small {
        padding-top: 60px;
    }
    &.main::before {
        background: rgb(45, 62, 80);
        background-repeat: no-repeat;
        background-position: left top;
        background-size: cover;
        transform: skewY(165deg);
        content: "";
        height: 460px;
        left: 0;
        position: absolute;
        top: -204px;
        width: 100%;
        z-index: -1;
    }
    @media #{$md-layout} {
        padding-top: 120px;
        &.main::before {
            background-image: inherit;
            height: auto;
        }
    }

    @media #{$sm-layout} {
        padding-top: 120px;
        &.main::before {
            background-image: inherit;
            height: auto;
        }
    }
    .footer-bg {
        background: rgba(45, 62, 80, 0.85) none repeat scroll 0 0;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    .section-title {
        h2 {
            @media #{$lg-layout} {
                font-size: 34px;
            }
            @media #{$md-layout} {
                font-size: 34px;
            }
            @media #{$sm-layout} {
                font-size: 34px;
            }
            
        }
    }
}

.contact-inner {
    .title {
        color: #ffffff;
        font-size: 28px;
        font-weight: 800;
        line-height: 20px;
        text-transform: uppercase;
    }
    .contact-form {
        margin-right: 21px;
        margin-top: 46px;
    }
    .input-box {
        margin-bottom: 15px;
        input {
            background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
            border: 1px solid #ffffff;
            border-radius: 8px;
            box-shadow: none;
            color: #cccccc;
            height: 42px;
            margin-bottom: 3px;
            padding: 8px 17px;
            width: 100%;
            display: block;
            font-size: 14px;
            line-height: 1.42857143;
            @include placeholder {
                color: #fff;
            }
        }
        textarea {
            height: 99px;
            resize: none;
            background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
            border: 1px solid #ffffff;
            border-radius: 8px;
            box-shadow: none;
            color: #cccccc;
            margin-bottom: 3px;
            padding: 8px 17px;
            @include placeholder {
                color: #fff;
            }
        }
        .submit-button {
            background-color: #7a9757;
            border-radius: 7px;
            color: #ffffff;
            font-family: $heading-font;
            height: 44px;
            margin-top: 21px;
            text-transform: uppercase;
            -webkit-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
            width: 100%;
            border: 0;
            &:hover {
                background-color: #fff;
                color: #2d3e50;
            }
        }
    }
}

.contact-button-container {
    display: flex;
    flex-wrap: wrap;
    .submit-button {
        width: 100%;
        background-color: #7a9757;
        border-radius: 7px;
        color: #ffffff;
        font-family: $heading-font;
        height: 44px;
        margin-top: 21px;
        text-transform: uppercase;
        -webkit-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
        border: 0;
        &:hover {
            background-color: #fff;
            color: #2d3e50;
        }
        a {
            color: #ffffff;
        }
    }
}

/*=====================
Footer Links 
======================*/

.footer-links a {
    border: 1px solid #fafbfb;
    border-radius: 100%;
    color: #ffffff;
    display: inline-table;
    font-size: 20px;
    height: 36px;
    margin: 0 6.5px;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 36px;
}

.footer-links a i {
    display: table-cell;
    vertical-align: middle;
}

.footer-links a:hover {
    background-color: #7a9757;
    border-color: #7a9757;
}

.footer-links {
    margin-top: 40px
}

.footer-text span {
    color: #cccccc
}

.footer-text {
    margin-top: 23px
}

.footer-text span a {
    color: #cccccc
}

.footer-text span a:hover {
    border-bottom: 1px solid #7a9757;
    color: #7a9757;
}

@media #{$sm-layout}{
    .newsletter-content .button{
        font-size: 12px;
    }
    .footer-links a {
        margin-bottom: 10px;
    }
}



