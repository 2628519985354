//===== Colors =====//
$theme-color: #7A9757;

$body-color: #2d3e50;
$heading-color: #2f2f2f;
$border-color: #d4d4d4;
$white:#fff;


/*===============================
    Font Family 
=================================*/
$body-font: 'Lato', sans-serif;
$heading-font: 'Raleway', sans-serif;


// Heading Font List
$font-1: 'Lato', sans-serif;
$font-2: 'Raleway', sans-serif;
$fontList: $font-1 , $font-2 ;


/*===============================
    Color Variation 
=================================*/
$color-1:  $theme-color;
$color-2:  #f4769a;
$color-3:  #2f21b3;
$color-4:  #8956e2;
$colorList: $color-1, $color-2 ,$color-3 ,$color-4;






//===== Line Height =====//
$body-line-height: 24px;
//==== Font size =====//
$body-font-size: 16px;
$h1: 64px;
$h2: 54px;
$h3: 24px;
$h4: 20px;
$h5: 18px;
$h6: 16px;


//===== Social Colors =====//
$facebook : #3B5998;
$twitter : #00ACEE;
$google-plus : #DD4B39;
$pinterest : #C8232C;
$linkedin : #0E76A8;
$flickr : #FF0084;
$google : #dd4b39;
$youtube : #cd201f;




// Layouts
$smlg-device: 'only screen and (max-width: 1199px)';

$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';

$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout:'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout:'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
/* container 450px*/
$small-mobile: 'only screen and (max-width: 479px)';
/* container 300px*/

@media #{$large-mobile} {
    .container {
        max-width: 450px;
    }
}

@media #{$small-mobile} {
    .container {
        max-width: 320px;
    }
}
@media (max-width: 1920px) and (min-width: 1400px){
    .container {
        max-width: 1200px;
    }
}